import React, { useState, useEffect, useRef } from 'react';
import {
    Modal, Box, TextField, Select, MenuItem, Button, IconButton,
    Typography, CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import SuccessScreen from './SuccessScreen';
import ErrorManager from './ErrorManager';
import { Autocomplete } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import './modalStyle.css';

const WabaModal = ({ open, onClose, channelId, channelName }) => {
    const [templates, setTemplates] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState('');
    const [templateIdSelecionado, setTemplateIdSelecionado] = useState('');
    const [dataHoraEnvio, setDataHoraEnvio] = useState('');
    const [arquivoCsv, setArquivoCsv] = useState(null);
    const [arquivoCsvUrl, setArquivoCsvUrl] = useState('');
    const [entryInternationalPhone, setEntryInternationalPhone] = useState(false);
    const [adicionarEtiqueta, setAdicionarEtiqueta] = useState(true);
    const [contatosDuplicados, setContatosDuplicados] = useState(true);
    const [fecharChat, setFecharChat] = useState(true);
    const [email, setEmail] = useState('');
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCsvUploaded, setIsCsvUploaded] = useState(false);
    const [isCsvDragActive, setIsCsvDragActive] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { acontID } = useParams();
    const { token } = useParams();
    const timeZone = 'America/Sao_Paulo';

    const errorManagerRef = useRef(null);

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_POLI_QA}/api/v1/templates/${acontID}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                if (!response.ok) {
                    throw new Error(`Erro ao buscar templates`);
                }
                const data = await response.json();
                setTemplates(data);
            } catch (error) {
                console.error('Erro ao buscar os canais');
                errorManagerRef.current.showError('Erro ao buscar canais');
            }
        };

        if (open) {
            fetchTemplates();
            fetchUsuarios(channelId); // Chame fetchUsuarios com o canal fixo
            const now = new Date();
            const zonedDate = formatInTimeZone(now, timeZone, "yyyy-MM-dd'T'HH:mm");
            setDataHoraEnvio(zonedDate);
        }
    }, [open, acontID, token, channelId, timeZone]);


    const fetchUsuarios = async (channelId) => {
        try {
            let usuarios = [];
            let currentPage = 1;
            let lastPage = 1;


            const firstResponse = await fetch(`${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=150&page=${currentPage}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }
            });

            if (!firstResponse.ok) {
                throw new Error('Erro ao buscar os usuários');
            }

            const firstData = await firstResponse.json();
            usuarios = firstData.data;
            lastPage = firstData.last_page;

            // Update state after fetching the first page
            setUsuarios(usuarios);

            // Fetch remaining pages if there are more pages
            if (currentPage < lastPage) {
                let allUsuarios = usuarios;

                while (currentPage < lastPage) {
                    currentPage++;
                    const response = await fetch(`${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=20&page=${currentPage}`, {
                        headers: {
                            'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                        }
                    });

                    if (!response.ok) {
                        throw new Error('Erro ao buscar os usuários');
                    }

                    const data = await response.json();
                    allUsuarios = [...allUsuarios, ...data.data];
                }

                // atualiza o estado apos buscar as paginas
                setUsuarios(allUsuarios);
            }
        } catch (error) {
            console.error('Erro ao buscar os usuários:', error);
            errorManagerRef.current.showError('Erro ao buscar os usuários');
        }
    };


    const handleFileChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.type === 'text/csv') {
                setArquivoCsv(file);
                const url = await handleFileUpload(file);
                if (url) {
                    setArquivoCsvUrl(url);
                    setIsCsvUploaded(true);
                } else {
                    errorManagerRef.current.showError('Erro ao fazer upload do arquivo.');
                }
            } else {
                errorManagerRef.current.showError('Por favor, selecione um arquivo .csv');
            }
        }
    };

    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_DISP}/upload/customer/${acontID}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                },
                body: formData
            });

            if (response.ok) {
                const data = await response.json();
                return data.fileUrl;
            } else {
                const errorText = await response.text();
                console.error(`Erro na requisição:`, errorText);
                errorManagerRef.current.showError('Erro no upload do arquivo: ' + errorText);
                return null;
            }
        } catch (error) {
            console.error('Erro ao enviar o arquivo:');
            errorManagerRef.current.showError('Erro ao enviar o arquivo');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!dataHoraEnvio || !templateIdSelecionado || !arquivoCsvUrl || !email) {
            errorManagerRef.current.showError('Por favor, preencha todos os campos obrigatórios.');
            setIsSubmitting(false);
            return;
        }

        const now = new Date();
        const selectedDate = new Date(dataHoraEnvio);

        // Se o tempo selecionado for passado ou presente, envie a solicitação diretamente para a API
        if (selectedDate <= now) {
            try {
                const formData = new FormData();
                formData.append('entry_tag', templateIdSelecionado);
                formData.append('entry_link_file', arquivoCsvUrl);
                formData.append('entry_chat_close', fecharChat ? 'true' : 'false');
                formData.append('entry_deduplicate_contact', contatosDuplicados ? 'true' : 'false');
                formData.append('entry_create_tag', adicionarEtiqueta ? 'true' : 'false');
                formData.append('entry_international_phone', entryInternationalPhone ? 'true' : 'false');
                formData.append('entry_email', email);

                const response = await fetch(
                    `${process.env.REACT_APP_URL_DISP}/trigger/WABA/${acontID}/${channelId}/${usuarioSelecionado}`,
                    {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'accept': 'application/json',
                            'Connection': 'keep-alive',
                        },
                        body: formData,
                    }
                );

                if (response.ok) {
                    setShowSuccessScreen(true);
                } else {
                    const errorText = await response.text();
                    errorManagerRef.current.showError(`Erro na requisição: ${response.status} - ${errorText}`);
                }
            } catch (error) {
                errorManagerRef.current.showError('Erro ao enviar os dados: ' + error.message);
            }
        } else {
            // If the selected time is in the future, send the request to Zapier
            const postData = {
                acontID: acontID,
                trigger_type: 'WABA',
                userID: usuarioSelecionado,
                channelID: channelId,
                token: token,
                dataHoraEnvio: dataHoraEnvio,
                templateIdSelecionado: templateIdSelecionado,
                entry_link_file: arquivoCsvUrl,
                fechar_chat: fecharChat,
                deduplicate_chat: contatosDuplicados,
                create_tag: adicionarEtiqueta,
                entry_international_phone: entryInternationalPhone,
                email: email,
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_ZAPIER}`, {
                    method: 'POST',
                    body: JSON.stringify(postData),
                });

                if (response.ok) {
                    setShowSuccessScreen(true);
                } else {
                    const errorText = await response.text();
                    errorManagerRef.current.showError(`Erro na requisição: ${response.status} - ${errorText}`);
                }
            } catch (error) {
                errorManagerRef.current.showError('Erro ao enviar os dados: ' + error.message);
            }
        }

        setIsSubmitting(false);
    };


    const handleRemoveCsv = () => {
        setArquivoCsv(null);
        setArquivoCsvUrl('');
        setIsCsvUploaded(false);
    };

    const handleCsvDragOver = (event) => {
        event.preventDefault();
        setIsCsvDragActive(true);
    };

    const handleCsvDragLeave = () => {
        setIsCsvDragActive(false);
    };

    const handleCsvDrop = async (event) => {
        event.preventDefault();
        setIsCsvDragActive(false);
        const file = event.dataTransfer.files[0];
        if (file && file.type === 'text/csv') {
            setArquivoCsv(file);
            const url = await handleFileUpload(file);
            if (url) {
                setArquivoCsvUrl(url);
                setIsCsvUploaded(true);
            } else {
                errorManagerRef.current.showError('Erro ao fazer upload do arquivo.');
            }
        } else {
            errorManagerRef.current.showError('Por favor, selecione um arquivo .csv');
        }
    };

    const handleInputChange = async (event, value) => {
        if (value) {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=150&entry_user_name=${value}`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setUsuarios(data.data);  // Atualiza o estado com os resultados da busca
                } else {
                    console.error('Erro ao buscar usuários:', response.statusText);
                    errorManagerRef.current.showError('Erro ao buscar usuários.');
                }
            } catch (error) {
                console.error('Erro ao buscar usuários:', error.message);
                errorManagerRef.current.showError('Erro ao buscar usuários.');
            }
        } else {
            // Se a busca for vazia, busca novamente todos os usuários
            fetchUsuarios(channelId);
        }
    };


    return (
        <>
            <ErrorManager ref={errorManagerRef} />
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal">
                    <form className="modal-content" onSubmit={handleFormSubmit}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <Box flexGrow={1} textAlign="center">
                                <Typography variant="h6" style={{ fontWeight: 'normal', margin: 0 }}>
                                    Criar campanha
                                </Typography>
                            </Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <div className="input-container">
                            <Typography variant="subtitle1" margin="normal">
                                Canal selecionado: <strong>{channelName}</strong>
                            </Typography>
                        </div>
                        <div className="input-container">
                            <Autocomplete
                                fullWidth
                                options={usuarios}
                                getOptionLabel={(option) => option.name}
                                value={usuarios.find(usuario => usuario.id === usuarioSelecionado) || null}
                                onChange={(event, newValue) => {
                                    setUsuarioSelecionado(newValue ? newValue.id : '');
                                }}
                                onInputChange={handleInputChange}  // Adicione esta linha para busca dinâmica
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Selecione o usuário"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                )}
                                noOptionsText="Digite para buscar"
                            />
                        </div>
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Data e horário de envio"
                                margin="normal"
                                type="datetime-local"
                                value={dataHoraEnvio}
                                onChange={(event) => setDataHoraEnvio(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </div>
                        <div className="input-container">
                            <Autocomplete
                                fullWidth
                                options={templates.filter(template => template.is_template === true && template.channel_id === channelId)}
                                getOptionLabel={(option) => option.tag}
                                value={templates.find(template => template.tag === templateIdSelecionado) || null}
                                onChange={(event, newValue) => {
                                    setTemplateIdSelecionado(newValue ? newValue.tag : '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Template da mensagem"
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                )}
                                noOptionsText="Nenhum template encontrado"
                            />
                        </div>
                        <div
                            className={`upload-container ${isCsvDragActive ? 'drag-active' : ''}`}
                            onDragOver={handleCsvDragOver}
                            onDragLeave={handleCsvDragLeave}
                            onDrop={handleCsvDrop}
                            style={{
                                border: '2px dashed #ccc',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                position: 'relative'
                            }}
                        >
                            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                <CloudUploadIcon style={{ marginRight: '8px', color: '#5a5a5a' }} />
                                <Typography variant="body1" style={{ marginRight: '16px' }}>
                                    Envie sua planilha em .csv
                                </Typography>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color="primary"
                                >
                                    {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Escolher Arquivo'}
                                    <input
                                        type="file"
                                        name="csvFile"
                                        accept=".csv"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </Button>
                            </Box>

                            {isCsvUploaded && (
                                <div className="file-preview" style={{ marginTop: '8px' }}>
                                    <a href={arquivoCsvUrl} download={arquivoCsv ? arquivoCsv.name : 'arquivo.csv'}>
                                        {arquivoCsv ? arquivoCsv.name : 'arquivo.csv'}
                                    </a>
                                    <div className="file-actions" style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                                        <IconButton onClick={handleRemoveCsv} size="small" className="remove-button">
                                            <DeleteIcon />
                                        </IconButton>
                                        <CheckCircleIcon className="check-icon" color="success" />
                                    </div>
                                </div>
                            )}
                        </div>

                        <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                            <Typography variant="caption" color="textSecondary" mr={2}>
                                Baixe o modelo no botão ao lado
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => window.open('https://docs.google.com/spreadsheets/d/1wrbJz7rIPBi26xssXseUD8UQT5esdmh6LN3fNMzyEyo/copy', '_blank')}
                            >
                                Planilha Modelo
                            </Button>
                        </Box>
                        <input type="hidden" name="arquivoCsvUrl" value={arquivoCsvUrl} required />
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Email"
                                margin="normal"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Ao ativar, caso o cliente responda o bot será acionado e o contato poderá ser distribuído</Typography>
                            <Select
                                fullWidth
                                value={fecharChat}
                                onChange={(e) => setFecharChat(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Ao ativar, evitará que o contato receba duas mensagens</Typography>
                            <Select
                                fullWidth
                                value={contatosDuplicados}
                                onChange={(e) => setContatosDuplicados(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Se ativo vai adicionar etiqueta ao contato</Typography>
                            <Select
                                fullWidth
                                value={adicionarEtiqueta}
                                onChange={(e) => setAdicionarEtiqueta(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Caso verdadeiro não vai adicionar DDI 55 nos telefones que não tem</Typography>
                            <Select
                                fullWidth
                                value={entryInternationalPhone}
                                onChange={(e) => setEntryInternationalPhone(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="button-container">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}  // Desativa o botão enquanto isSubmitting for true
                            >
                                {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Enviar'}
                            </Button>
                        </div>
                    </form>
                    {showSuccessScreen && <SuccessScreen />} {/* Mostrar tela de sucesso */}
                </div>
            </Modal>
        </>
    );
};

export default WabaModal;