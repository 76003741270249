import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Modal, Box, TextField, Select, MenuItem, Button, IconButton, FormControl,
    Typography, CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { Autocomplete } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SuccessScreen from '../SuccessScreen'; // Corrija o caminho conforme necessário
import ErrorManager from '../ErrorManager';
import '../modalStyle.css';

const BrokerModal = ({ open, onClose, channelId, channelName }) => {
    const [usuarioSelecionado, setUsuarioSelecionado] = useState('');
    const [usuarios, setUsuarios] = useState([]);
    const [dataHoraEnvio, setDataHoraEnvio] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [arquivoCsv, setArquivoCsv] = useState(null);
    const [arquivoCsvUrl, setArquivoCsvUrl] = useState('');
    const [entryInternationalPhone, setEntryInternationalPhone] = useState(false);
    const [adicionarEtiqueta, setAdicionarEtiqueta] = useState(true);
    const [contatosDuplicados, setContatosDuplicados] = useState(true);
    const [fecharChat, setFecharChat] = useState(true);
    const [email, setEmail] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCsvUploaded, setIsCsvUploaded] = useState(false);
    const [isCsvDragActive, setIsCsvDragActive] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const errorManagerRef = useRef(null);


    const { acontID, token } = useParams();
    const timeZone = 'America/Sao_Paulo';;


    // Função fetchUsuarios encapsulada com useCallback
    const fetchUsuarios = useCallback(async () => {
        if (!channelId) return;

        try {
            let usuarios = [];
            let currentPage = 1;
            let lastPage = 1;

            const firstResponse = await fetch(`${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=150&page=${currentPage}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                }
            });

            if (!firstResponse.ok) {
                throw new Error();
            }

            const firstData = await firstResponse.json();
            usuarios = firstData.data;
            lastPage = firstData.last_page;

            setUsuarios(usuarios);

            let allUsuarios = usuarios;

            while (currentPage < lastPage) {
                currentPage++;
                const response = await fetch(`${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=20&page=${currentPage}`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`Erro ao buscar os usuários:`);
                }

                const data = await response.json();
                allUsuarios = [...allUsuarios, ...data.data];
            }

            setUsuarios(allUsuarios);
        } catch (error) {
            console.error('Erro ao buscar os usuários:', error.message);
            errorManagerRef.current.showError('Erro ao buscar os usuários');
        }
    }, [channelId]); // Dependência de channelId

    // useEffect que depende de open e fetchUsuarios
    useEffect(() => {
        if (open) {
            const now = new Date();
            fetchUsuarios();
            setDataHoraEnvio(formatInTimeZone(now, timeZone, "yyyy-MM-dd'T'HH:mm"));
        }
    }, [open, fetchUsuarios, timeZone]);


    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_DISP}/upload/customer/${acontID}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                },
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                return result.fileUrl;
            } else {
                const errorText = await response.text();
                console.error('Erro no upload do arquivo:', errorText);
                errorManagerRef.current.showError('Erro no upload do arquivo: ' + errorText);
                return null;
            }
        } catch (error) {
            console.error('Erro no upload do arquivo:', error.message);
            errorManagerRef.current.showError('Erro no upload do arquivo: ' + error.message);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const handleFileChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.type === 'text/csv') {
                setArquivoCsv(file);  // Certifique-se de que arquivoCsv está sendo corretamente definido
                const url = await handleFileUpload(file);
                if (url) {
                    setArquivoCsvUrl(url);
                    setIsCsvUploaded(true);
                }
            } else {
                console.error('Por favor, selecione um arquivo .csv');
                errorManagerRef.current.showError('Por favor, selecione um arquivo .csv');
            }
        }
    };

    const handleRemoveCsv = () => {
        setArquivoCsv(null);
        setArquivoCsvUrl(''); // Certifique-se de que o URL também seja resetado
        setIsCsvUploaded(false);
    };
    // Manipuladores para arrastar e soltar CSV
    const handleCsvDragOver = (event) => {
        event.preventDefault();
        setIsCsvDragActive(true);
    };

    const handleCsvDragLeave = () => {
        setIsCsvDragActive(false);
    };

    const handleCsvDrop = async (event) => {
        event.preventDefault();
        setIsCsvDragActive(false);
        const file = event.dataTransfer.files[0];
        if (file && file.type === 'text/csv') {
            setArquivoCsv(file);
            const url = await handleFileUpload(file);
            if (url) {
                setArquivoCsvUrl(url);
                setIsCsvUploaded(true);
            }
        } else {
            console.error('Por favor, selecione um arquivo .csv');
            errorManagerRef.current.showError('Por favor, selecione um arquivo .csv');
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        setIsSubmitting(true);  // Ativar o estado de carregamento

        // Validate required fields
        if (!usuarioSelecionado || !dataHoraEnvio || !mensagem || !arquivoCsv || !email) {
            errorManagerRef.current.showError('Por favor, preencha todos os campos obrigatórios.');
            setIsSubmitting(false);  // Desativar o estado de carregamento
            return;
        }

        // Realizar o upload da imagem e do arquivo CSV antes de enviar os dados
        const csvUrl = arquivoCsv ? await handleFileUpload(arquivoCsv) : '';
        //const imageUrl = imagem ? await handleImageUpload(imagem) : '';

        // Check if the selected date and time is in the past or future
        const now = new Date();
        const selectedDate = new Date(dataHoraEnvio);

        if (selectedDate <= now) {
            // Fazendo requisicao para api poli
            try {
                const formData = new FormData();
                formData.append('entry_msg', mensagem);
                formData.append('entry_chat_close', fecharChat ? 'true' : 'false');
                formData.append('entry_deduplicate_contact', contatosDuplicados ? 'true' : 'false');
                formData.append('entry_link_file', csvUrl || '');
                //formData.append('entry_link_image', imageUrl || '');
                formData.append('entry_email', email);
                formData.append('entry_create_tag', adicionarEtiqueta ? 'true' : 'false');
                formData.append('entry_international_phone', entryInternationalPhone ? 'true' : 'false');

                const response = await fetch(
                    `${process.env.REACT_APP_URL_DISP}/trigger/INSTAGRAM/${acontID}/${channelId}/${usuarioSelecionado}`,
                    {
                        method: 'POST',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'accept': 'application/json',
                            'Connection': 'keep-alive',
                        },
                        body: formData,
                    }
                );

                if (response.ok) {
                    setShowSuccessMessage(true);
                } else {
                    const errorText = await response.text();
                    errorManagerRef.current.showError(`Erro na requisição: ${response.status} - ${errorText}`);
                }
            } catch (error) {
                errorManagerRef.current.showError('Erro ao enviar os dados: ' + error.message);
            }
        } else {
            // Send the request to Zapier
            const postData = {
                //title: titulo,
                acontID: acontID,
                trigger_type: 'INSTAGRAM',
                userID: usuarioSelecionado,
                channelID: channelId,
                token: token,
                dataHoraEnvio: dataHoraEnvio,
                message: mensagem,
                entry_link_file: csvUrl,
                //entry_link_image: imageUrl,
                fechar_chat: fecharChat,
                deduplicate_chat: contatosDuplicados,
                create_tag: adicionarEtiqueta,
                entry_international_phone: entryInternationalPhone,
                email: email
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_ZAPIER}`, {
                    method: 'POST',
                    body: JSON.stringify(postData)
                });

                if (response.ok) {
                    setShowSuccessMessage(true);
                } else {
                    const errorText = await response.text();
                    errorManagerRef.current.showError(`Erro na requisição: ${response.status} - ${errorText}`);
                }
            } catch (error) {
                errorManagerRef.current.showError('Erro ao enviar os dados: ' + error.message);
            }
        }
        // Ensure that the loading state is disabled after the request
        setIsSubmitting(false);
    };

    const handleInputChange = async (event, value) => {
        if (value) {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=150&entry_user_name=${value}`, {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_API_TOKEN}`
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    setUsuarios(data.data);  // Atualiza o estado com os resultados da busca
                } else {
                    console.error('Erro ao buscar usuários:', response.statusText);
                }
            } catch (error) {
                console.error('Erro ao buscar usuários:', error.message);
            }
        } else {
            setUsuarios([]);  // Limpa a lista se não houver valor na busca
        }
    };


    return (
        <>
            <ErrorManager ref={errorManagerRef} /> {/* Include the ErrorManager component */}
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal">
                    {showSuccessMessage && <SuccessScreen />} {/* Mostrar a tela de sucesso */}
                    <form className="modal-content" onSubmit={handleFormSubmit}>
                        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                            <Box flexGrow={1} textAlign="center">
                                <Typography variant="h6" component="h2" style={{ fontWeight: 'normal', margin: 0 }}>
                                    Criar campanha Instagram
                                </Typography>
                            </Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <div className="input-container">
                            <Typography variant="subtitle1" margin="normal">
                                Canal selecionado: <strong>{channelName}</strong>
                            </Typography>
                        </div>
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Mensagem"
                                margin="normal"
                                multiline
                                rows={4}
                                value={mensagem}
                                onChange={(e) => setMensagem(e.target.value)}
                                required
                            />
                        </div>


                        <div className="input-container">
                            <FormControl fullWidth margin="normal" required>
                                <Autocomplete
                                    fullWidth
                                    options={usuarios}
                                    getOptionLabel={(option) => option.name}
                                    value={usuarioSelecionado ? usuarios.find(user => user.id === usuarioSelecionado) : null}
                                    onChange={(event, newValue) => {
                                        setUsuarioSelecionado(newValue ? newValue.id : '');
                                    }}
                                    onInputChange={handleInputChange}  // Adiciona esta linha para ativar a busca dinâmica
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Selecione o usuário"
                                            margin="normal"
                                            fullWidth
                                        />
                                    )}
                                    noOptionsText="Digite para buscar"
                                />
                            </FormControl>
                        </div>
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Data e horário de envio"
                                margin="normal"
                                type="datetime-local"
                                value={dataHoraEnvio}
                                onChange={(event) => setDataHoraEnvio(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </div>

                        <div
                            className={`upload-container ${isCsvDragActive ? 'drag-active' : ''}`}
                            onDragOver={handleCsvDragOver}
                            onDragLeave={handleCsvDragLeave}
                            onDrop={handleCsvDrop}
                            style={{
                                border: '2px dashed #ccc',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                position: 'relative'
                            }}
                        >
                            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                <CloudUploadIcon style={{ marginRight: '8px', color: '#5a5a5a' }} />
                                <Typography variant="body1" style={{ marginRight: '16px' }}>
                                    Envie sua planilha em .csv
                                </Typography>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color="primary"
                                >
                                    {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Escolher Arquivo'}
                                    <input
                                        type="file"
                                        name="csvFile"
                                        accept=".csv"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </Button>
                            </Box>

                            {isCsvUploaded && (
                                <div className="file-preview" style={{ marginTop: '8px' }}>
                                    <a href={arquivoCsvUrl} download={arquivoCsv ? arquivoCsv.name : 'arquivo.csv'}>
                                        {arquivoCsv ? arquivoCsv.name : 'arquivo.csv'}
                                    </a>
                                    <div className="file-actions" style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                                        <IconButton onClick={handleRemoveCsv} size="small" className="remove-button">
                                            <DeleteIcon />
                                        </IconButton>
                                        <CheckCircleIcon className="check-icon" color="success" />
                                    </div>
                                </div>
                            )}
                        </div>
                        <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                            <Typography variant="caption" color="textSecondary" mr={2}>
                                Baixe o modelo no botão ao lado
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => window.open('https://docs.google.com/spreadsheets/d/1wrbJz7rIPBi26xssXseUD8UQT5esdmh6LN3fNMzyEyo/copy', '_blank')}
                            >
                                Planilha Modelo
                            </Button>
                        </Box>
                        {/* Hidden input to hold the file URL and make it required */}
                        <input type="hidden" name="arquivoCsvUrl" value={arquivoCsvUrl} required />
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Email"
                                margin="normal"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Ao ativar, caso o cliente responda o bot será acionado e o contato poderá ser distribuído</Typography>
                            <Select
                                fullWidth
                                value={fecharChat}
                                onChange={(e) => setFecharChat(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Ao ativar, evitará que o contato receba duas mensagens</Typography>
                            <Select
                                fullWidth
                                value={contatosDuplicados}
                                onChange={(e) => setContatosDuplicados(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Se ativo vai adicionar etiqueta ao contato</Typography>
                            <Select
                                fullWidth
                                value={adicionarEtiqueta}
                                onChange={(e) => setAdicionarEtiqueta(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Caso verdadeiro não vai adicionar DDI 55 nos telefones que não tem</Typography>
                            <Select
                                fullWidth
                                value={entryInternationalPhone}
                                onChange={(e) => setEntryInternationalPhone(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="button-container">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}  // Desativa o botão enquanto isSubmitting for true
                            >
                                {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Enviar'}
                            </Button>
                        </div>
                        {showSuccessMessage && (
                            <Box display="flex" justifyContent="center" alignItems="center" mt={2} className="success-message">
                                <CheckCircleIcon color="success" />
                                <Typography variant="subtitle1" className="success-text">Dados enviados com sucesso!</Typography>
                            </Box>
                        )}
                    </form>
                </div >
            </Modal >
        </>
    );
};

export default BrokerModal;